<template>
  <div class="mt-15">
      
<v-img
              
              src="../../public/img/Nymphenburg_long.png"/>
            
 
           
           <v-layout align-center justify-center>

         
            
              <v-card flat min-width="300" max-width="660" class="px-8" style="text-align:justify;  font-size: 20px;">
                <br><br>
               

                <div class="mx-auto mb-10 mt-10" :style="SubTitle">
                  Über Uns:
               </div>
               Der Hilfsverein Nymphenburg existiert als eingetragener Verein und Stiftung. Wir fördern Humanitäre Arbeit und moderne Ansätze zur Entwicklungshilfe vor allem im Ausland, mit dem Fokus auf langfristigen Partnerschaften und Nachhaltigkeit.  
               <br><br>
               Stiftungszweck ist die Förderung des Wohlfahrtswesens, die Förderung der Entwicklungszusammenarbeit sowie die Unterstützung hilfsbedürftiger Personen. Weiterhin soll sie ihre Zwecke durch die Förderung von Tätigkeiten steuerbegünstigter Körperschaften, welche humanitäre Projekte zugunsten hilfsbedürftiger und in Not geratener Personen durchführen, erfüllen. Die Stiftung gewährt Hilfe, um den Lebensunterhalt hilfsbedürftiger Personen zu unterstützen. Die Arbeit der Stiftung ist dabei nicht auf Deutschland beschränkt. Sie soll vor allem in Ländern tätig werden, in denen besondere Not herrscht.  
               <br><br>
               Die Stiftung Hilfsverein Nymphenburg wurde am 02. August 2013 von Herzog Franz von Bayern errichtet. Historische Grundlagen liegen in dem Hilfsverein Nymphenburg e.V. Der gemeinnützige Verein war 1964 von Herzog Albrecht und Herzogin Marita von Bayern im Zuge der Hilfe für Menschen in der Sowjetunion gegründet worden. Mit der Öffnung Osteuropas ab 1990 änderte und erweiterte sich die Aufgabenstellung. Es begann die Projektarbeit vor Ort. Seit 2006 galt die Hilfe des Vereins auch bestimmten Regionen in Afrika. 2014 wurde die Projektarbeit auf die Stiftung Hilfsverein Nymphenburg übertragen.  
               <br><br>
               Die Finanzierung der Hilfsprojekte erfolgt durch private Spenden und Zuwendungen von Unternehmen, anderer Institutionen und Stiftungen und in einem sehr geringen Umfang durch Erträge aus der Vermögensverwaltung. Unsere ehrenamtlichen Länder-Projektmanager stellen eine wichtige Schnittstelle zwischen der Stiftung und den Projektpartnern dar. 
              
               <div class="mx-auto mb-3 mt-6" :style="SectionTitle">
                  Stiftungsrat: 
               </div>
               Herzog Franz von Bayern, <br>Herr Thomas Greinwald, <br>Graf Guy Moy, <br>Graf Heinrich Spreti<br>
               <div class="mx-auto mb-3 mt-6" :style="SectionTitle">
                Vorstand Stiftung: 
             </div>
              Prinz Ludwig von Bayern,<br> 
              Herr Stephan Warsberg
              <div class="mx-auto mb-3 mt-6" :style="SectionTitle">
                Vorstand e.V.: 
             </div>
              Graf Heinrich Spreti, <br> 
              Gräfin Andrea Erdödy

              <div class="mx-auto mb-3 mt-6" :style="SectionTitle">
                Ehrenamtliche Projektmanager: 
             </div>
            Frau Angelika Köppel,<br> 
            Frau Elisabeth von Liel,<br>
            Dr. Graf László Teleki, <br>
            Graf Hubertus Nesselrode, <br>
            Karl-Ludwig von Poschinger
             <br>
            <div class="mx-auto mb-3 mt-6" :style="SectionTitle">
            Mitarbeiter der Verwaltung: 
            </div>
              Dr. Andrea Buhl,
            <br>
              Günter Mekelburg,
            <br>
              Dr. Barbara Wagner 
            <div class="mx-auto mb-3 mt-6" :style="SectionTitle">
              Kuratorium: 
           </div>
            Herzog Franz von Bayern (Vorsitz)<br>
            Magdalena Batliner<br>
            Charlotte von Bomhard<br>
            Conrado Dornier<br>
            Ernst Frank<br>
            Dr. Sophia Franke<br>
            Thomas Greinwald<br>
            Dr. Reiner Hagemann<br>
            Prof. Dr. Claus Hipp<br>
            Dr. Jobst Kayser-Eichberg<br>
            Alexander Koepnick<br>
            Alfred H. Lehner<br>
            Andreas Mach<br>
            Erzherzogin Anna Gabriele von Österreich<br>
            Erzherzog Michael von Österreich<br>
            Dr. h.c. Bernd Pischetsrieder<br>
            Georg Randlkofer<br>
            Margarete Riedel<br>
            Hans-Peter Rien<br>
            Prinzessin Elisabeth zu Sayn-Wittgenstein<br>
            Florian Seidel<br>
            Katrin Stoll<br>
            Dr. Graf Rupert Strachwitz<br>
            Fürst Erich von Waldburg-Zeil<br>
            Ernst Wunderlich<br>


                  

            </v-card>
            

            
              
            
                  
        </v-layout>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'About',
  components: {
  },
  data(){
    return{

    
        items: [
          {
            src: '../../public/img/slides/slide.jpg',
          },
          {
            src: '../../public/img/slides/slide-2.jpg',
          },
          {
            src: '../../public/img/slides/slide-2.jpg',
          },
          {
            src: '../../public/img/slides/slide-2.jpg',
          },
        ],
    }
  },
  computed: {
    SubTitle () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-size:13px; font-weight: bold; text-align: center;'
            case 'sm': return 'font-size:15px; font-weight: bold; text-align: center;'
            case 'md': return 'font-size:20px; font-weight: bold; text-align: center;'
            case 'lg': return 'font-size:25px; font-weight: bold; text-align: center;'
            case 'xl': return 'font-size:30px; font-weight: bold; text-align: center;'
            default: return 'font-size:25px'

            }
        },
        SectionTitle () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-size:13px; font-weight: bold; '
            case 'sm': return 'font-size:14px; font-weight: bold;'
            case 'md': return 'font-size:16px; font-weight: bold;'
            case 'lg': return 'font-size:18px; font-weight: bold;'
            case 'xl': return 'font-size:20px; font-weight: bold;'
            default: return 'font-size:25px'

            }
        },
        IconSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '60px'
            case 'sm': return '75px'
            case 'md': return '90px'
            case 'lg': return '120px;'
            case 'xl': return '150px;'
            default: return '25px'

            }
        },
        SponsorLogoSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '75px'
            case 'sm': return '85px'
            case 'md': return '100px'
            case 'lg': return '120px;'
            case 'xl': return '140px;'
            default: return '25px'

            }
        },

        MapSize () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '300px'
            case 'sm': return '400px'
            case 'md': return '500px'
            case 'lg': return '600px;'
            case 'xl': return '600px;'
            default: return '25px'

            }
        },
        SliderTitle () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 'font-size:30px; color:rgb(40,53,130); font-weight: bold;'
            case 'sm': return 'font-size:40px; color:rgb(40,53,130); font-weight: bold;'
            case 'md': return 'font-size:50px; color:rgb(40,53,130); font-weight: bold;'
            case 'lg': return 'font-size:80px; color:rgb(40,53,130); font-weight: bold;'
            case 'xl': return 'font-size:100px; color:rgb(40,53,130); font-weight: bold;'
            default: return 'font-size:40px; color:rgb(40,53,130); font-weight: bold;'

            }
        },
        ImageHeight () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return '300'
            case 'sm': return '400'
            case 'md': return '500'
            case 'lg': return '600'
            case 'xl': return '700'
            default: return '400'

            }
        },
        
  },
  methods:{
    GotoDonate: function() {
          this.$router.push('donate')
      },
      CleverRoute: function (strRoute) {
      if (!window.location.href.includes("reset")) {
        this.$router.push({ path: strRoute });
      } else {
        this.$router.push({ path: "." + strRoute });
      }
    },
  }
}
</script>
